var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tw-merge",rawName:"v-tw-merge"}],staticClass:"flex-1 flex flex-col gap-4 bg-purple-background"},[_c('div',{staticClass:"container flex-1 flex flex-col gap-4 py-4 px-8"},[_c('div',{staticClass:"flex flex-col gap-6 items-start"},[_c('router-link',{class:_vm.buttonVariants({ variant: 'naked', class: 'gap-2' }),attrs:{"to":{ name: 'Member programs' }}},[_c('ChevronLeftIcon'),_vm._v(" Terug ")],1),_c('h1',{staticClass:"m-0 text-3xl text-gray-900"},[_vm._v(" "+_vm._s(_vm.personalTraining.name)+" ")])],1),_c('div',{staticClass:"flex flex-col gap-1 p-1 pb-0 flex-1"},[_c('div',{staticClass:"-mx-4 -my-3 flex flex-row gap-2 overflow-x-auto px-4 py-3"},_vm._l((_vm.personalTraining.weeks),function(week,index){return _c('div',{key:week.number,class:_vm.cx(
							'-m-1 flex-1 p-1',
							week.number === _vm.props.programWeek && 'week-corners rounded-t-2xl bg-purple-lighter'
						),attrs:{"data-place":index === 0 ? 'first' : index === _vm.personalTraining.totalWeeks - 1 ? 'last' : undefined}},[_c('router-link',{class:_vm.cx(
								'relative flex h-full flex-col items-center rounded-xl p-1.5 text-center text-sm font-bold',
								week.completed && 'opacity-60',
								_vm.personalTraining.isCurrent && week.number === _vm.personalTraining.currentWeek
									? ' p-1.75 from-purple-gradient-start to-purple-gradient-end bg-gradient-to-r text-white'
									: week.number === _vm.props.programWeek
										? 'text-white bg-blue border border-solid border-blue'
										: 'border border-solid border-purple-lighter bg-white text-black hover:bg-black-50 active:bg-black-50'
							),attrs:{"to":{
							params: {
								week: week.number,
								day:
									week.number === _vm.personalTraining.currentWeek ? _vm.personalTraining.currentDay : 1,
							},
						},"replace":""}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.weekLabel))]),_c('span',[_vm._v(_vm._s(week.number))]),(week.completed)?_c('pfg-icon',{staticClass:"pointer-events-none absolute -right-1 -top-1 rounded-full bg-blue p-1 text-white",attrs:{"icon-id":"icon_check","width":"0.5rem","height":"0.5rem"}}):_vm._e()],1)],1)}),0),(_vm.personalTraining.weeks[_vm.props.programWeek - 1])?_c('div',{class:_vm.cx(
						'-mx-1 flex flex-row gap-2 rounded-2xl bg-purple-lighter p-1 overflow-x-auto',
						_vm.props.programWeek === 1 && 'rounded-tl-none',
						_vm.props.programWeek === _vm.personalTraining.totalWeeks && 'rounded-tr-none',
						_vm.props.programDay === 1 && 'rounded-bl-none',
						_vm.props.programDay === _vm.personalTraining.daysPerWeek && 'rounded-br-none'
					)},_vm._l((_vm.personalTraining.weeks[_vm.props.programWeek - 1].days),function(day){return _c('div',{key:day.number,class:_vm.cx(
							'-m-1 flex-1 p-1',
							day.number === _vm.props.programDay && 'day-corners rounded-t-1.5xl bg-white'
						)},[_c('router-link',{class:_vm.cx(
								'text-inherit relative flex flex-1 flex-col items-center gap-2.5 rounded-xl border border-solid border-purple-lighter px-2 py-3 text-center text-sm font-bold min-w-12',
								day.completed && 'opacity-60',
								_vm.personalTraining.isCurrent &&
									_vm.props.programWeek === _vm.personalTraining.currentWeek &&
									day.number === _vm.personalTraining.currentDay
									? 'from-purple-gradient-start to-purple-gradient-end bg-gradient-to-r text-white'
									: day.number === _vm.props.programDay
										? 'border border-solid border-blue bg-white'
										: 'text-gray-900 bg-white hover:bg-black-50 active:bg-black-50'
							),attrs:{"to":{ params: { day: day.number } },"replace":""}},[_c('FontAwesomeIcon',{staticClass:"w-5 h-5",attrs:{"icon":day.number === _vm.props.programDay ? _vm.pfgSolidSchema : _vm.pfgOutlineSchema}}),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.dayLabel)+" "+_vm._s(day.number))]),(day.completed)?_c('pfg-icon',{staticClass:"pointer-events-none absolute -right-1 -top-1 rounded-full bg-blue p-1 text-white",attrs:{"icon-id":"icon_check","width":"0.5rem","height":"0.5rem"}}):_vm._e()],1)],1)}),0):_vm._e(),_c('div',{staticClass:"-mt-1 -mx-1 flex flex-col flex-1"},[(_vm.personalTraining.weeks[_vm.props.programWeek - 1])?_c('div',{staticClass:"flex flex-row h-8"},_vm._l((_vm.personalTraining.weeks[_vm.props.programWeek - 1].days),function(day){return _c('div',{key:day.number,class:_vm.cx('flex-1', day.number === _vm.props.programDay && 'divider-corners')})}),0):_vm._e(),_c('div',{staticClass:"bg-white flex-1 rounded-t-3xl flex flex-col container-escape text-gray-900"},[(_vm.status === 'loading')?_c('pfg-loader',{staticClass:"py-12",attrs:{"loading":""}}):(_vm.status === 'success' && !!_vm.data)?_c('TrainingDayForm',{attrs:{"member-id":_vm.props.memberId,"program-id":_vm.props.programId,"program-week":_vm.props.programWeek,"program-day":_vm.props.programDay,"training-day":_vm.data,"all-days-in-week":_vm.personalTraining.allDaysInWeek}}):_c('ErrorAlert',{attrs:{"error":_vm.error}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }